import React from 'react';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';

const RedirectTemplate: React.FC = () => {
  const [, setCookie] = useCookies();

  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 24 * 60 * 60 * 1000);
  setCookie('SeeInvisible', true, { path: '/', expires: expiryDate });

  if (typeof window !== `undefined`) {
    navigate('/');
  }

  return <div>Je zal zien wat andere kan niet...maar alleen voor een dag :-)</div>;
};

export default RedirectTemplate;
